import {useState, useEffect, useRef, memo} from 'react';
import {connect} from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import Marker from "./Marker";

import {
  getCommentToolboxMarkersLoadingState,
  getCommentToolboxMarkersState,
  getCommentToolboxState,
  getRulerClickedState,
  getPainterStartData,
} from "../../../store/selectors";

import {
  setCommentToolboxState, setEraserClickedState, setRulerClickedState,
  setDrawerToolboxClickedState, setLineDrawToolboxState, setStickyNotesClickedState, setPolygonDrawToolboxState,
} from "../../../store/actions/painterStart";
import {
  setCommentToolboxMarkersLoadingState, setCommentToolboxMarkersState
} from "../../../store/actions/mapStateAction";

// import {isMobile} from "react-device-detect";
import {
  changeCursorIcon,
  CURSOR_TYPE,
  generateUniqueId
} from "../../../shared/mockData";

import CommentBox from "../../../assets/imgs/PaintBar/comment-box-icon.svg";
import "./style.css";
import {isMobile} from "react-device-detect";

const CommentToolbox = (props) => {

  const {
    getCommentToolboxState,
    setCommentToolboxState,
    getCommentToolboxMarkersState,
    setCommentToolboxMarkersState,
    getCommentToolboxMarkersLoadingState,
    setCommentToolboxMarkersLoadingState,
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setRulerClickedState,
    setStickyNotesClickedState,
    setLineDrawToolboxState,
    getPainterStartData,
    setPolygonDrawToolboxState,
    map
  } = props;

  const [markers, setMarkers] = useState([]);
  const markersCopy = useRef(null); // Array
  const commentBoxFunctionHandler = useRef(null); // Function Reference
  const currentMarkerRef = useRef(null); // Current Marker Reference
  const mapIsMoving = useRef(false);
  const isAnyMarkerMoving = useRef(false);
  const handleDeleteMarkerRef = useRef(null);
  const setMarkerInfoParamByIDRef = useRef(null);
  const refOfsetCurrentMarkerRef = useRef(null);

  if(isMobile) {
    map.on('moveend', () => { mapIsMoving.current = false; });
    map.on('dragstart', () => { mapIsMoving.current = true; });
  }

  const handleMapClick = (e) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();

    if (isMobile && (mapIsMoving.current || isAnyMarkerMoving.current)) {
      return;
    }

    const coordinates = e.lngLat;

    const newMarker = {
      id: uuidv4(),
      coordinates: coordinates,
      description: '',
      color: '#2C476C',
    };

    setMarkers(prevState => [...prevState, newMarker]);
  };

  const setCurrentMarkerRef = (ref) => {
    currentMarkerRef.current = ref;
  };

  const setMarkerInfoParamByID = (id, param, value) => {
    const index = markersCopy.current.findIndex(marker => marker.id === id); // will return -1, if no marker with provided id

    if (index !== -1) { // if not exist
      const newMarkers = [...markersCopy.current];
      newMarkers[index][param] = value;
      setMarkers(newMarkers);
    } else {
      console.error(`Marker with id ${id} not found.`);
    }
  };

  const handleDeleteMarker = (id) => {
    const copyOfMarkers = [...markersCopy.current];
    const newMarkers = copyOfMarkers.filter(marker => marker.id !== id);
    setMarkers(newMarkers);
  };

  const handleIconClick = () => {
    setDrawerToolboxClickedState(false);
    setEraserClickedState(false);
    setRulerClickedState(false);
    setPolygonDrawToolboxState(false);
    setStickyNotesClickedState(false);
    setLineDrawToolboxState(false);
    setCommentToolboxState(!getCommentToolboxState);
  }

  useEffect(() => {
    markersCopy.current = [...markers];
    setCommentToolboxMarkersState([...markersCopy.current]);
  }, [markers]);

  useEffect(() => {
    if(!getCommentToolboxMarkersLoadingState) {
      setMarkers(prevState => [...getCommentToolboxMarkersState]);
      setCommentToolboxMarkersLoadingState(true);
    }
  }, [getCommentToolboxMarkersLoadingState]);


  useEffect(() => {
    if (getPainterStartData && getCommentToolboxState){
      commentBoxFunctionHandler.current = handleMapClick;
      map.on(`${isMobile ? "touchend" : "click"}`, commentBoxFunctionHandler.current);
      setTimeout(() => {changeCursorIcon(CURSOR_TYPE.COMMENT_BOX)}, 50)
    } else {
      if (currentMarkerRef.current && currentMarkerRef.current.getPopup().isOpen()) {
        currentMarkerRef.current.getPopup().remove();
      }
      map.off(`${isMobile ? "touchend" : "click"}`, commentBoxFunctionHandler.current);
      changeCursorIcon()
    }
  }, [getPainterStartData, getCommentToolboxState])

  useEffect(() => {
    handleDeleteMarkerRef.current = handleDeleteMarker;
    setMarkerInfoParamByIDRef.current = setMarkerInfoParamByID;
    refOfsetCurrentMarkerRef.current = setCurrentMarkerRef;
  }, []);

  return (
    <>
      <div
        id="commentBox_icon"
        className={`pain_items ${getCommentToolboxState ? "button_active" : ""}`}
        onClick={handleIconClick}
      >
        <img src={CommentBox} alt="" className="icon_img"/>
      </div>
      {
        getCommentToolboxMarkersState &&
        getCommentToolboxMarkersState.length !== 0 &&
        getCommentToolboxMarkersState.map(marker =>
            <Marker key={marker.id}
                    isAnyMarkerMoving={isAnyMarkerMoving}
                    id={marker.id}
                    description={marker.description}
                    color={marker.color}
                    coordinates={marker.coordinates}
                    map={map}
                    currentMarkerRef={currentMarkerRef}
                    handleDeleteMarker={handleDeleteMarkerRef.current}
                    setMarkerInfoParamByID={setMarkerInfoParamByIDRef.current}
                    setCurrentMarkerRef={refOfsetCurrentMarkerRef.current}
            /> )
      }
    </>
  );
};

const mapStateTopProps = (state) => ({
  getCommentToolboxState: getCommentToolboxState(state),
  getCommentToolboxMarkersState: getCommentToolboxMarkersState(state),
  getRulerClickedState: getRulerClickedState(state),
  getPainterStartData: getPainterStartData(state),
  getCommentToolboxMarkersLoadingState: getCommentToolboxMarkersLoadingState(state),
});

const mapDispatchToProps = {
  setCommentToolboxState: setCommentToolboxState,
  setPolygonDrawToolboxState: setPolygonDrawToolboxState,
  setDrawerToolboxClickedState: setDrawerToolboxClickedState,
  setEraserClickedState: setEraserClickedState,
  setRulerClickedState: setRulerClickedState,
  setCommentToolboxMarkersState: setCommentToolboxMarkersState,
  setCommentToolboxMarkersLoadingState: setCommentToolboxMarkersLoadingState,
  setLineDrawToolboxState: setLineDrawToolboxState,
  setStickyNotesClickedState: setStickyNotesClickedState,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(CommentToolbox));
