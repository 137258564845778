import './style.css';
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import { mapLiveAPIRequest } from "../../pages/map/utils/mapPreviewMode";
import { getCategoriesData, getFilteredCategoriesData, getLegends } from "../../store/selectors";
import { connect } from "react-redux";
import { getLegendsFromAPI } from "../../store/actions/legends";
import { getCategoriesFromAPI, getFilteredCategoriesFromAPI } from "../../store/actions/categories";
import ArticleModeHeader from "../articleModeHeader";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const BasicMap = (props) => {
    const { content } = props;
    const map = useRef(null);
    const mapContainer = useRef(null);

    const setupMap = async () => {
        if (!mapContainer.current) return;
        if (map.current) {
            const gl = map.current.getCanvas().getContext('webgl');
            if (gl) {
                const loseContextExt = gl.getExtension('WEBGL_lose_context');
                if (loseContextExt) {
                    loseContextExt.loseContext();
                }
            }
            map.current.remove();
        }

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: `${process.env.REACT_APP_GIS_URL}/BaseMap/1?articleId=${content?.articleId}`,
            center: [0, 0],
            maxZoom: 8.1,
            zoom: 1,
            transformRequest: (url, resourceType) => {
                if (url.startsWith(process.env.REACT_APP_GIS_URL)) {
                    return {
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }
                    };
                }
            }
        });

        map.current.on('load', async () => {
            const query = `?articleId=${content?.articleId}`;
            const legends = await props.getLegendsFromAPI(query);
            const resData = await mapLiveAPIRequest(content?.articleId, 'article');

            const categories = props.categories;
            const res = props.filteredCategories;
            const subCat = {};
            const cat = {};

            categories?.forEach((el) => {
                cat[el.id] = el;
                if (Array.isArray(el.subCategories)) {
                    el.subCategories.forEach((item) => {
                        subCat[item.id] = item;
                    });
                }
            });

            const features = [];

            if (resData.clusterElements.length > 0) {
                features.push(...resData.clusterElements.reduce((acum, el) => {
                    const subId = res.getById(el?.articleIds[0])?.subcategory?.[0]?.subCategoryId;
                    const catId = res.getById(el?.articleIds[0])?.categoryId;
                    acum.push({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [el.location.longitude, el.location.latitude]
                        },
                        properties: {
                            id: el.id,
                            relationType: el.lessonRelationType,
                            articleIds: el.articleIds,
                            name: el.displayName || 'no name ',
                            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
                            startTime: 0,
                            endTime: 0,
                            visible: 'visible',
                            relationFilter: 'visible',
                            iconPath: 'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
                            key: legends?.getKeyById(el?.layerId)?.key,
                            subId: subId,
                            catId: catId,
                            color: cat[catId]?.color,
                            catColor: cat[catId]?.color,
                            element: true,
                        }
                    });
                    return acum;
                }, []));
            }

            if (resData.clusterArticles.length > 0) {
                features.push(...resData.clusterArticles.reduce((acum, el, idx) => {
                    const catInfo = cat[el?.subcategory?.[0]?.categoryId];
                    const subCatInfo = subCat[el?.subcategory?.[0]?.subCategoryId];
                    acum.push({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [el.location.longitude, el.location.latitude]
                        },
                        properties: {
                            visible: 'visible',
                            relationFilter: 'visible',
                            id: el.id,
                            relationType: el.lessonRelationType,
                            articleIds: [el.id],
                            name: el.content[0]?.shortTitle ? el.content[0]?.shortTitle : (el.content[0]?.mainTitle || 'no name '),
                            yearStart: +`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                            yearEnd: +`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear() }`,
                            startTime: 0,
                            endTime: 0,
                            catId: catInfo?.id,
                            subId: subCatInfo?.id,
                            iconPath: subCatInfo?.iconPath,
                            color: subCatInfo?.color || '#fff',
                            key: subCatInfo?.key,
                            catColor: catInfo?.color,
                            isArticle: true,
                            active: idx === 0
                        }
                    });
                    return acum;
                }, []));
            }

            const sourceOptions = {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": features
                },
                cluster: false,
            };

            if (map.current.getSource('Brainograph PIN GIS API')) {
                map.current.removeSource('Brainograph PIN GIS API');
            }

            map.current.addSource('Brainograph PIN GIS API', sourceOptions);

            map.current.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'Brainograph PIN GIS API',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': ['get', 'catColor'],
                    'circle-radius': 15,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': ['get', 'catColor'],
                },
            });

            map.current.addLayer({
                id: 'unclustered-text',
                type: 'symbol',
                source: 'Brainograph PIN GIS API',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'text-field': ['get', 'name'],
                    'text-font': ['GHEA Grapalat Regular'],
                    'text-offset': [0, 0.9],
                    'text-anchor': 'top',
                    "text-size": 16,
                    "text-allow-overlap": false,
                    "text-letter-spacing": 0.1,
                    'icon-image': ['get', 'key'],
                    'icon-size': 1,
                    'icon-anchor': 'center',
                    'icon-offset': [0, 0]
                },
                paint: {
                    'icon-halo-blur': 1,
                    'icon-halo-width': 1.3,
                    "text-color": '#000000',
                    'text-halo-blur': 1,
                    'text-halo-color': 'rgba(255, 255, 255, 0.3)',
                    'text-halo-width': 1.3,
                }
            });
        });
    };

    useEffect(() => {
        setupMap();
        return () => {
            if (map.current) {
                const gl = map.current.getCanvas().getContext('webgl');
                if (gl) {
                    const loseContextExt = gl.getExtension('WEBGL_lose_context');
                    if (loseContextExt) {
                        loseContextExt.loseContext();
                    }
                }
                map.current.remove();
                map.current = null;
            }
        };
    }, [content.articleId]);

    return (
        <section className={'basic-map-box'}>
            <ArticleModeHeader id={content?.articleId} />
            <div id={"basic-map"} ref={mapContainer}></div>
        </section>
    );
};

const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    legends: getLegends(state)
});

const mapDispatchToProps = {
    getLegendsFromAPI,
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI
};

export default connect(mapStateTopProps, mapDispatchToProps)(BasicMap);
