import html2canvas from "html2canvas";
import {presentationPostMethod, extractResult} from "../service/presentationMode";

export const dataURLToBinary = (dataURL) => {
    var arr = dataURL.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return u8arr;
}
export const handleCaptureTypeBlob = (callBack, elementId, elementRef) => {
    if (elementId !== 'map') {
        const loadImages = async (element) => {
            const images = element.querySelectorAll('img');
            const promises = Array.from(images).map((img) => {
                return new Promise((resolve, reject) => {
                    const originalSrc = img.src;
                    const timestampedSrc = `${originalSrc}?v=${new Date().getTime()}`;
                    const newImg = new Image();
                    newImg.crossOrigin = 'anonymous';
                    newImg.src = timestampedSrc;
                    newImg.onload = () => {
                        img.src = timestampedSrc;
                        resolve();
                    };
                    newImg.onerror = reject;
                });
            });
            return Promise.all(promises);
        };
        const adjustTextContent = (element) => {
            const titleElements = element.querySelectorAll('.article-read-more-header__title h2');
            titleElements.forEach(title => {
                title.innerHTML = title.innerHTML.replace(/և/g, 'ԵՒ');
            });
        };

        const targetElement = elementRef?.current || document.getElementById(elementId) || document.getElementById('root');
        if (targetElement) {
            adjustTextContent(targetElement);
            loadImages(targetElement).then(() => {
                setTimeout(() => {
                    html2canvas(targetElement, { useCORS: true, allowTaint: false })
                        .then((canvas) => {
                            canvas.toBlob(callBack, 'image/jpeg');
                        })
                        .catch((error) => {
                            console.error('Error capturing screenshot with html2canvas: ', error);
                        });
                }, 500);
            }).catch((error) => {
                console.error('Error loading images: ', error);
            });
        }
    } else {
        const targetElement = elementRef?.current || document.getElementById(elementId) || document.getElementById('root');
        if (targetElement) {
            const stickyNoteContainers = document.querySelectorAll('.sticky_note_container');
            const clonedContainers = [];

            stickyNoteContainers.forEach(container => {
                const clone = container.cloneNode(true);
                targetElement.appendChild(clone);
                clonedContainers.push(clone);
            });
            html2canvas(targetElement)
                .then((canvas) => {
                    canvas.toBlob(callBack, 'image/jpeg');
                    clonedContainers.forEach(clone => {
                        targetElement.removeChild(clone);
                    });
                })
                .catch((error) => {
                    console.error('Error capturing screenshot with html2canvas: ', error);
                });
        }
    }
};

export const uploadImage = (blob,alt,title,access) => {
    const url =`${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
    var formData = new FormData();
    formData.append('content', blob,'capturedImage.jpeg');
    formData.append('title', title || 'capturedImage.jpeg')
    formData.append('alt', alt || title || 'capturedImage.jpeg')
    formData.append('access', access || "PublicRead")
    return presentationPostMethod(url,formData)
            .then(extractResult)
}
