import React, { Component } from "react"
import Enter from "../UI/Enter"
import "./style.css"
import PageLoader from "../UI/PageLoader";
import {withTranslation} from "react-i18next";
import {withNavigation} from "../../pages/accountInfo";

const Loading = () => "..."

const NotFound = ({text}) => (
  <div
    style={{
      width: "100%",
      padding: "30px",
      textAlign: "center",
    }}
  >
      {text}
  </div>
)

class AccountDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
        firstLoad : true
    }
  }
    componentDidUpdate(){
      const {type,data,selectedItem,selectItem} = this.props
      if(data.length && selectedItem !== data[0].id && (type === 'subject' || type === 'grade') && this.state.firstLoad ){
          selectItem(data[0].id)
          this.setState({firstLoad:false})
      }
    }
  render() {
    const {
      type,
      width,
      data,
      isLoading,
      heading,
      color,
      goToMap,
      searchFilter,
      selectItem,
      selectedItem,
        t
    } = this.props

    return (
      <div className="account-dashboard" style={{ width: width }}>
        <div className="dashboard-topic" style={{ backgroundColor: color }}>
          <p className="dashboard-heading">{heading}</p>
        </div>
        <div className="dashboard-content">
          <div className="dashboard-filter">
            <input
              type="text"
              placeholder={this.props.t('search')}
              onChange={(e) => searchFilter(e.target.value)}
            />
          </div>
          <ul className="dashboard-list">
              {isLoading &&(
                  <div
                      style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          display: "flex",
                          justifyContent: "center",
                      }}
                  >
                      <PageLoader />
                  </div>
              )}

            {isLoading === false && data && data.length === 0 && heading !== "Առարկա" &&  <NotFound text={t('nothingFound')} />}

            {isLoading === false &&
              data &&
              data.map((item) => (
                <li
                  key={item.value}
                  className={`dashboard-item ${
                    selectedItem === item.id ? "active" : null
                  }`}
                  onClick={() => {
                      selectItem(item.id);
                      type === 'lesson' && goToMap(type, item.id)
                  }}
                >
                  <button className="dashboard-main-btn" type="button">
                    {item.title}
                  </button>
                  <p
                    className="current-title"
                    style={{ backgroundColor: color }}
                  >
                    {item.title}
                  </p>
                  <div className="dashboard-item-icons">
                    {type === "topic"? (
                      <button
                        className="main__subitem-label"
                        onClick={(e) => {
                            e.stopPropagation()
                            goToMap(type, item.id)
                        }}
                      >
                        <Enter show={true} />
                      </button>
                    ) : null}

                    {type !== "lesson" ? (
                      <button className="dashboard-arrow" type="button">
                        <svg
                          version="1.1"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fillRule="evenodd"
                            strokeDasharray="0,0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              transform="translate(10 10) rotate(180) translate(-10 -10)"
                              fillRule="nonzero"
                              stroke="#000"
                              stroke-width="1.5"
                            >
                              <path d="m12.5 16.6-5.4333-5.4333c-0.64167-0.64167-0.64167-1.6917 0-2.3333l5.4333-5.4333" />
                            </g>
                          </g>
                        </svg>
                      </button>
                    ) : <div className="right-empty-box"></div>}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default  withTranslation()(AccountDashboard)
