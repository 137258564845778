export const DRAWER_TOOLBOX_LAYER = "drawer-toolbox-layer";
export const RULER_LAYER_AND_SOURCE_NAME = "ruler-layer-and-source";
export const RULER_POINTS_LAYER_NAME = "ruler-points-layer";
export const ERASER_TOOLBOX_LAYER = "eraser-toolbox-layer";
export const MINIMAL_COORDINATES_LENGTH_COUNT = 1;
export const color_picker_data = [
    "#fefffe", "#ececec", "#d7d7d7", "#c3c3c3", "#aeafaf", "#9b9b9b", "#868686",
    "#707171", "#5c5c5c", "#444444", "#303030", "#000000", "#003449", "#011756",
    "#090138", "#2a013b", "#3a0114", "#3a0114", "#5a1600", "#573000", "#573000",
    "#666200", "#4e5500", "#213c06", "#004c65", "#022b7c", "#130452", "#430559",
    "#550725", "#840900", "#7c2400", "#7c4a00", "#7a5700", "#8e8700", "#707704",
    "#355613", "#006e90", "#0340aa", "#090138", "#022b7c", "#130452", "#430559",
    "#550725", "#840900", "#7c2400", "#7c4a00", "#7a5700", "#8e8700", "#707704",
    "#355613", "#006e90", "#0340aa", "#280278", "#61127d", "#7a1339", "#b71300",
    "#ad3c01", "#a96800", "#a77d00", "#c5be03", "#9ca707", "#4d7b23", "#008db6",
    "#0555d7", "#341395", "#7b1b9f", "#9a1f4e", "#e31f00", "#db5000", "#d48402",
    "#d29f00", "#f5ed01", "#c4d211", "#66a031", "#00a3d9", "#0861fd", "#4c1cb4",
    "#9925be", "#ba295d", "#ff3e0d", "#ff6a01", "#ffae00", "#fcc803", "#fefb40",
    "#daed34", "#77bd3f", "#01c8fc", "#3788fd", "#5e2cec", "#bf35f3", "#e7387b",
    "#fe624f", "#fe8746", "#feb63c", "#fecc3c", "#fff76b", "#e5f065", "#97d45f",
    "#51d7fc", "#75a8ff", "#874ffd", "#d455fe", "#ef729f", "#ff8d82", "#fea67e",
    "#fec878", "#feda78", "#fffa95", "#ebf391", "#b2de8c", "#94e4fc", "#a8c7ff",
    "#b28dfe", "#e393fe", "#f4a5c1", "#ffb7b1", "#ffc6ad", "#fedaa9", "#fde5aa",
    "#fffbbb", "#f1f7b9", "#cee9b7", "#ccf0ff", "#d3e3fe", "#d9cafe", "#efcbfe",
    "#f9d4e1", "#ffdbd9", "#ffe3d7", "#feedd5", "#fef2d6", "#fdfbde", "#f6fadc",
    "#dfefd5",
];